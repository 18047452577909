import { createMakeStyles, CSSObject } from "tss-react";
import { Theme, useTheme } from "@mui/material";

const { makeStyles: tssMakeStyles } = createMakeStyles({ useTheme });

type UseStylesReturnType<T> = keyof T extends never
    ? Record<string, string> 
    : { [k in keyof T]: string } 

type UseStyles<Props, T> = T extends infer R 
    ? never extends keyof Props 
        ? () => UseStylesReturnType<R> 
        : (props: Props) => UseStylesReturnType<R>
    : never

export function makeStyles<Props, T extends string = never>(
    styles: Record<T, CSSObject> | ((theme: Theme, props: Props) => Record<T, CSSObject>)
): UseStyles<Props, Record<T, string>> {
    const useStyles = tssMakeStyles<Props>()(styles);
    return ((props = {}) => useStyles(props as Props).classes) as never;
}
